<template>
  <form v-bind="$attrs" class="feedback-form">
    <div class="feedback-form__form">
      <div class="feedback-form__input_container feedback-form__name">
        <div class="feedback-form__input">
          <input
            class="input"
            placeholder="Имя"
            id="username"
            v-model="state.form.name"
            @input="state.error.name = undefined"
          />
        </div>
        <span class="error-message" v-if="state.error.name">{{
          state.error.name
        }}</span>
      </div>
      <div class="feedback-form__input_container feedback-form__phone">
        <div class="feedback-form__input">
          <input
            class="input"
            id="phone"
            placeholder="Телефон"
            type="tel"
            v-mask="['+7 ### ###-##-##']"
            v-model="state.form.phone"
            @input="state.error.phone = undefined"
          />
        </div>
        <span v-if="state.error.phone" class="error-message">{{
          state.error.phone
        }}</span>
      </div>
      <div class="feedback-form__input_container feedback-form__comment">
        <div class="feedback-form__input">
          <textarea
            class="input"
            id="comment"
            placeholder="Ваш комментарий"
            v-model="state.form.comment"
            @input="state.error.comment = undefined"
          />
        </div>
        <span v-if="state.error.comment" class="error-message">{{
          state.error.comment
        }}</span>
      </div>
      <div class="feedback-form__input_container feedback-form__policy">
        <span class="feedback-form__privacy-policy">
          Нажимая кнопку “Отправить” вы соглашаетесь с
          <a href="#"> политикой конфиденциальности </a>
        </span>
      </div>
    </div>
    <button class="btn_with_corner" @click.prevent="submit">Отправить</button>
  </form>
</template>

<script setup>
import { Teleport, reactive } from "vue";
import { sendForm } from "@/service/form.js";

const location = window.location;
const stringLocation = location.toString();

const queryParams = stringLocation.split("?")[1];
let utmTags = {};

if (queryParams) {
  const utmParams = queryParams.split("&");

  utmParams.forEach((v) => {
    const tag = v.toUpperCase();
    if (tag.includes("UTM_SOURCE")) {
      utmTags.UTM_SOURCE = tag.split("=")[1];
    }
    if (tag.includes("UTM_MEDIUM")) {
      utmTags.UTM_MEDIUM = tag.split("=")[1];
    }
    if (tag.includes("UTM_CAMPAIGN")) {
      utmTags.UTM_CAMPAIGN = tag.split("=")[1];
    }
  });
}

const emits = defineEmits(["formSended"]);

const state = reactive({
  form: {
    name: "",
    phone: "",
    comment: "",
  },
  error: {
    name: undefined,
    phone: undefined,
    comment: undefined,
  },
});

const submit = async () => {
  try {
    const sendObj = { ...state.form };
    if (Object.values(utmTags).length) {
      Object.assign(sendObj, utmTags);
    }
    await sendForm(sendObj);
    emits("formSended");
  } catch (error) {
    state.error.name = error.name;
    state.error.phone = error.phone;
    state.error.comment = error.comment;
  }
};
</script>

<style lang="scss">
.feedback-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 25px;

  .input {
    width: 94%;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  &__input_container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;

    .error-message {
      color: #cb1c1c;

      font-family: "Exo 2";
      font-size: 12px;
      line-height: 15px;
      text-align: left;
    }

    &:has(.error-message) {
      .feedback-form__input {
        &,
        &::before,
        &::after {
          border-color: #cb1c1c;
        }
      }
    }
  }

  &__name,
  &__phone {
    margin-bottom: 30px;
  }

  &__comment {
    margin-bottom: 10px;
  }

  &__input {
    position: relative;
    overflow: hidden;

    border-width: 0 0 0 2px;
    border-left: 2px solid #d8d8d8;

    &::after,
    &::before {
      box-sizing: border-box;
      position: absolute;
      content: "";
      width: 100%;
      background-color: #f9fdff;
      border: 2px solid #d8d8d8;
      left: 0px;
      z-index: -1;
    }

    &::before {
      height: 20px;
      left: -19px;
      bottom: 0px;
      border-width: 0 3px 2px 0px;
      transform: skew(-45deg);
      transform-origin: right bottom;
    }

    &::after {
      height: calc(100% - 20px);
      top: 0px;
      border-width: 2px 2px 0 0px;
    }

    .input {
      padding-left: 20px;
      font-family: "Exo 2";
      font-size: 20px;

      border: none;

      box-sizing: border-box;
      margin: 2px 20px 2px 2px;
      min-height: 46px;

      background: transparent;

      &:focus {
        outline: none;
      }

      &:-webkit-autofill {
        &,
        &:hover,
        &:focus,
        &:active {
          -webkit-background-clip: text;
          -webkit-text-fill-color: #000000;
          transition: background-color 5000s ease-in-out 0s;
          box-shadow: inset 0 0 20px 20px #f9fdff;
        }
      }

      &::placeholder {
        color: #b9b9b9;
      }
    }

    textarea {
      resize: none;
      padding-top: 12px;
      height: 123px;
    }
  }

  &__privacy-policy {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;

    color: #000000;

    a {
      color: #000000;
    }
  }
}
</style>
